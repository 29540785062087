<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small"
						@click="config.isDetailDisplay = false">返回</el-button> </p>
				<div class="h-b"><el-button type="primary" size="small" @click="editApi"
						v-if="!config.isAdd && config.isCanEdit"  v-right-code="'Vehicletype:Edit'">编辑</el-button> </div>
				<div class="h-b"><el-button type="primary" size="small" @click="saveApi"
						v-if="config.isAdd"  v-right-code="'Vehicletype:Edit'">保存</el-button>
				</div>
			</div>
		</div>

		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane name="tabbasic">
				<span slot="label">基本信息</span>
				<div class="form-list">
					<el-form :model="dataSource" ref="_editForm" :rules="validateRule">
						<el-collapse v-model.trim="formItems">
							<el-collapse-item title="基本信息" name="formItem">
								<el-form-item>
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>车型编码：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="Code">
											<el-input v-model="dataSource.Code" :maxlength="100" :minlength="1"
												placeholder="车型编码" :disabled="true"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>车型名称：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="Name">
											<el-input v-model="dataSource.Name" :maxlength="100" :minlength="1"
												placeholder="车型名称" :disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="3" class="form-title">货箱类型：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="CartonType">
											<DictionarySelect v-model="dataSource.CartonType"  datType="Int"
												dicTypeCode="CartonTypeCode" :disabled="!config.isAdd">
											</DictionarySelect>
										</el-form-item>
									</el-col>								
								</el-form-item>
								<el-form-item>
									<el-col :span="3" class="form-title">
										<span style="color:red;">*</span>动力：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="PowerType">
											<DictionarySelect v-model="dataSource.PowerType" datType="Int" dicTypeCode="PowerTypeCode"
												:disabled="!config.isAdd"></DictionarySelect>
										</el-form-item>
									</el-col>
									<el-col :span="3" class="form-title">温层属性：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="TemperatureProperty">
											<DictionarySelect v-model="dataSource.TemperatureProperty"  datType="Int" dicTypeCode="TemperaturePropertyCode"
												:disabled="!config.isAdd"></DictionarySelect>
										</el-form-item>
									</el-col>
								</el-form-item>
								
								<el-form-item>
									<el-col :span="3" class="form-title">车规格（mm）：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="Length">
											<el-input type="number" v-model="dataSource.Length" placeholder="请输入"
												:disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="5">
										<el-form-item prop="Width">
											<el-input type="number" v-model="dataSource.Width" placeholder="请输入"
												:disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="5">
										<el-form-item prop="Height">
											<el-input type="number" v-model="dataSource.Height" placeholder="请输入"
												:disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
								</el-form-item>
								
								<el-form-item>
									<el-col :span="3" class="form-title"> 厢规格（mm）：
									</el-col>
									<el-col :span="5">
										<el-form-item prop="CartonLength">
											<el-input type="number" v-model="dataSource.CartonLength" placeholder="请输入"
												:disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="5">
										<el-form-item prop="CartonWidth">
											<el-input type="number" v-model="dataSource.CartonWidth" placeholder="请输入"
												:disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="5">
										<el-form-item prop="CartonHeight">
											<el-input type="number" v-model="dataSource.CartonHeight" placeholder="请输入"
												:disabled="!config.isAdd"></el-input>
										</el-form-item>
									</el-col>
									
								</el-form-item>
								<el-form-item>
									<el-col :span="3" class="form-title">备注：
									</el-col>
									<el-col :span="21">
										<el-form-item prop="Remark">
											<el-input type="textarea" v-model.trim="dataSource.Remark" :maxlength="200">
											</el-input>
										</el-form-item>
									</el-col>
								</el-form-item>
							</el-collapse-item>
							<el-collapse-item title="装载属性" name="formItem1">
								<el-form-item>
									<el-table :data="tableData"  border style="width: 100%">
										<el-table-column prop="RateVolume" label="额定体积（cm3）">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.RateVolume"
													placeholder="额定体积（cm3）"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="VolumeUpper" label="体积上限（cm3）">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.VolumeUpper"
													placeholder="体积上限（cm3）"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="VolumeLower" label="体积下限（cm3）">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.VolumeLower"
													placeholder="体积下限（cm3）"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="RateWeight" label="额定重量（kg）">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.RateWeight"
													placeholder="额定重量（kg）"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="WeightUpper" label="重量上限（kg）">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.WeightUpper"
													placeholder="重量上限（kg）"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="WeightLower" label="重量下限（kg）">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.WeightLower"
													placeholder="重量下限（kg）"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="RatedNumber" label="额定件数">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.RatedNumber"
													placeholder="额定件数"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="NumberUpper" label="件数上限">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.NumberUpper"
													placeholder="件数上限"></el-input>
											</template>
										</el-table-column>
										<el-table-column prop="NumberLower" label="件数下限">
											<template slot-scope="scope">
												<el-input type="number" v-model="scope.row.NumberLower"
													placeholder="件数下限"></el-input>
											</template>
										</el-table-column>
									</el-table>
								</el-form-item>
							</el-collapse-item>
						</el-collapse>

					</el-form>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import {
		valHooks
	} from 'jquery';
	export default {
		mounted() {},
		data() {
			return {
				activeName: "tabbasic",
				formItems: ["formItem", "formItem1"],
				validateRule: {
					PowerType: [{
						required: true,
						message: '请选择动力类型',
						trigger: 'change'
					}],
					Name: [{
						required: true,
						message: '请输入车型名称',
						trigger: 'blur'
					}],
					RateVolume: [{
						required: true,
						message: '请输入额定体积',
						trigger: 'blur'
					}],
					RateWeight: [{
						required: true,
						message: '请输入额定载重',
						trigger: 'blur'
					}],
				},
				tableData: [

				],
				dataSource: {

				},
			};
		},
		props: {
			config: {
				isDetailDisplay: false,
				dataSourceKey: null,
			},
		},
		watch: {
			'config.dataSourceKey': {
				handler(curVal, oldVal) {
					if (curVal && curVal > 0) {
						this.getDetail(curVal); //初始化
					}else{
						if(curVal==null){
							this.dataSource={};
							this.initTable();
						}
						
					}
				},
				deep: true
			},
		},
		methods: {
			add() {
				var _this = this;
				this.$ajax.send("omsapi/vehicletype/getempty", "get", {}, (data) => {
					_this.dataSource = data.Result;
					_this.initTable();
					_this.$nextTick(function () {
						_this.$refs["_editForm"].clearValidate();
					});
				});
			},
			initTable() {
				var data = {};
				this.tableData = [];
				if (this.dataSource.Id != null) {
					data = {
						RateVolume: this.dataSource.RateVolume,
						VolumeUpper: this.dataSource.VolumeUpper,
						VolumeLower: this.dataSource.VolumeLower,
						RateWeight: this.dataSource.RateWeight,
						WeightLower: this.dataSource.WeightLower,
						WeightUpper: this.dataSource.WeightUpper,
						RatedNumber: this.dataSource.RatedNumber,
						NumberUpper: this.dataSource.NumberUpper,
						NumberLower: this.dataSource.NumberLower
					};
				} else {
					data = {
						RateVolume: null,
						VolumeUpper: null,
						VolumeLower: null,
						RateWeight: null,
						WeightLower: null,
						WeightUpper: null,
						RatedNumber: null,
						NumberUpper: null,
						NumberLower: null
					};
				}
				this.tableData.push(data);
			},
			getTableData() {
				this.dataSource.RateVolume = this.tableData[0].RateVolume;
				this.dataSource.VolumeUpper = this.tableData[0].VolumeUpper;
				this.dataSource.VolumeLower = this.tableData[0].VolumeLower;
				this.dataSource.RateWeight = this.tableData[0].RateWeight;
				this.dataSource.WeightLower = this.tableData[0].WeightLower;
				this.dataSource.WeightUpper = this.tableData[0].WeightUpper;
				this.dataSource.RatedNumber = this.tableData[0].RatedNumber;
				this.dataSource.NumberUpper = this.tableData[0].NumberUpper;
				this.dataSource.NumberLower = this.tableData[0].NumberLower;

			},
			editApi() {
				this.config.isAdd = true;
			},
			detailApi(apiId) {
				this.config.isAdd = false;
			},
			getDetail(apiId) {
				var _this = this;
				this.$ajax.send("omsapi/vehicletype/get", "get", {
					id: apiId
				}, (data) => {
					_this.dataSource = data.Result;
					_this.initTable();
				});
			},
			saveApi() {
				this.$refs["_editForm"].validate((valid) => {
					if (valid) {
						this.getTableData();
						var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
						var obj = this.Utils.cloneObj(this.dataSource);
						this.$ajax.send("omsapi/vehicletype/" + actionName, "post", obj, (data) => {
							this.config.dataSourceKey = data.Result;
							this.config.isCanEdit = false;
							this.config.isAdd = false;
							//this.detailApi(curVal)
							this.Event.$emit("reloadRolePageList");
							this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						this.Utils.messageBox("存在必填信息未输入.", "error");
						return false;
					}
				});
			},
		},
		components: {}
	}
</script>